<div class="containe apt-pact-list">
    <div class="row pact-list-header">
        <div class="col-6 pact-heading">Condition</div>
        <div class="col-4 pact-heading">Level</div>
        <div class="col-2 pact-heading">Heat</div>
    </div>

    <div class="pact-list">
        <div *ngFor="let condition of pacts; index as i;">
            <div class="row align-items-center" *ngIf="condition.name != 'Personal Liability' || hellMode"> 
                <div class="col-6 pact" [ngClass]="{'locked' : condition.locked}">{{ condition.name }}</div>
                <div class="col-2">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-xs-2 level" [ngClass]="{'locked' : condition.locked}">{{ condition.selectedLevel }}</div>
                        <div class="col-xs-2">
                            <button *ngIf="condition.locked" class="lock locked" alt="unlock this level" (click)="unlock([i])"></button>
                            <button *ngIf="!condition.locked" class="unlocked-lock" alt="lock in this level" (click)="lock([i])"></button>
                        </div>
                    </div>
                </div>

                <div class="col-2">
                    <div class="row justify-content-center">
                        <div class="col-2 counter-button-wrapper">
                            <button class="minus-level" [ngClass]="{'locked' : condition.locked}" (click)="decrement([i])" [disabled]="condition.locked || isMinIndex(condition)">-</button>
                        </div>
                        <div class="col-2 counter-button-wrapper">
                            <button class="plus-level" [ngClass]="{'locked' : condition.locked}" (click)="increment([i])" [disabled]="condition.locked || isMaxIndex(condition)">+</button>
                        </div>
                    </div>
                </div>
                <div class="col-2 heat-small">{{ heatValue(condition) }}🔥</div>
            </div>
        </div>
    </div>

    <div class="container pact-list-footer">
        <div class="row justify-content-end">
            <div class="heat-large">Total: {{totalHeat()}}🔥</div>
        </div>
        <br>
        <div class="row justify-content-center">
            <div class="custom-control custom-switch custom-switch-lg">
                <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="hellMode" [(checked)]="hellMode" (change) = "hellModeToggled(hellMode)">
                <label class="custom-control-label heat-small" for="customSwitch1">Hell Mode Active</label>
            </div>
        </div>
        <br>
        <div class="row justify-content-center align-items-center">
            <div class="heat-large">Target Heat:</div>
            <input class="target-input" type="number" min=0 max=64 [(ngModel)]="targetHeat">
            <button class="randomize-button" (click)="randomize()">Randomize</button>
        </div>
    </div>
</div>

