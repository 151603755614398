<div class="container main-background">
    <div class="row justify-content-center">
        <h1>{{title}}</h1>
    </div>
    <div class="row justify-content-center">
        <app-pact-list></app-pact-list>
    </div>

    <br>
    <hr>
    
    <footer class="container page-footer">
        <div class="row justify-content-center">
            <!-- Twitter -->
          <a class="twitter-link" href="https://twitter.com/gkgames" target="_blank">
            <fa-icon [icon]="faTwitter"></fa-icon> @gkgames
          </a>
        </div>
    </footer>
</div>